<template>
	<var-style-provider :style-vars="{ '--dialog-width': '420px'}">
		<var-dialog
			v-model:show="show"
			confirm-button-text="Select"
			cancel-button-text="Cancel"
			@before-close="onBeforeClose">
			<template #title>
				Select Commit
			</template>

			<var-skeleton :rows="7" :loading="loading">
				<var-radio-group v-model="selectedCommit" @change="onSelect">
					<var-radio v-for="commit in commits" :checked-value="commit.sha">{{ commit.message }}</var-radio>
				</var-radio-group>

				<var-pagination
					:simple="false"
					:current="page"
					:total="total"
					@change="pageChanged"
					:show-size-changer="false"
				/>

				<var-checkbox v-if="type === 'start'" v-model="fullDeploy" @change="onCheck">Deploy the entire repository</var-checkbox>
			</var-skeleton>
		</var-dialog>
	</var-style-provider>
</template>

<script>
import request from "../request";

export default {
	name: "SelectCommitModal",
	emits: ['onConfirm'],
	props: {
		serverId: {
			type: Number,
			required: true
		},
		branch: {
			type: String,
			required: true
		}
	},
	watch: {
		show(newVal, oldVal) {
			if (newVal && !this.commits) {
				this.getCommits()
			}
		}
	},
	data() {
		return {
			loading: true,
			show: false,
			page: 1,
			rpp: 10,
			total: 0,
			commits: null,
			selectedCommit: null,
			fullDeploy: false,
			type: null,
			onBeforeClose: (action, done) => {
				if (action === 'confirm') {
					let commit = this.commits.filter(obj => {
						return obj.sha === this.selectedCommit
					})[0]
					this.$emit('onConfirm', commit, this.type, this.fullDeploy)
					done()
				} else {
					done()
				}
			},
		}
	},
	methods: {
		getCommits() {
			this.loading = true;
			request.get(process.env.VUE_APP_BASE_URL +
				'/servers/' +
				this.serverId +
				'/commits?branch=' +
				this.branch +
				'&page=' +
				this.page +
				'&rpp=' +
				this.rpp
			)
				.then(data => {
					this.loading = false
					this.commits = data.data
					this.total = data.total
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		pageChanged(page) {
			this.loading = true
			this.page = page
			this.getCommits()
		},
		onSelect() {
			this.fullDeploy = false
		},
		onCheck() {
			this.selectedCommit = null
		}
	}
}
</script>

<style scoped>

</style>