<template>
	<var-skeleton :rows="7" :loading="loading || !servers || !branches">
		<var-row>
			<breadcrumbs current-item-title="New Deployment" :items="breadcrumbItems" />
		</var-row>
		<var-card
			title="New Deployment"
			class="mb-4"
		>
			<template #extra>
				<var-form ref="form" :disabled="loadingCreate">
					<var-select
						v-if="servers"
						placeholder="Server"
						:rules="[v => !!v || 'Please select a server to deploy']"
						v-model="formData.server_id"
						@change="selectDefaultBranch"
						class="mb-4"
					>
						<var-option v-for="server in servers" :label="server.name" :value="server.id" />
					</var-select>
					<var-loading :loading="loadingManifest">
						<var-select
							v-if="branches"
							placeholder="Branch"
							:rules="[v => !!v || 'You should pick a branch']"
							v-model="formData.branch"
							@change="getManifest"
							class="mb-3"
						>
							<var-option v-for="branch in branches" :label="branch.name" :value="branch.id" />
						</var-select>
					</var-loading>
					<template v-if="endCommit">
						<var-cell>Start Commit</var-cell>
						<commit-cell
							v-if="startCommit"
							:hash="startCommit.sha"
							:message="startCommit.message"
							:hash-short="startCommit.sha_short"
							:user="startCommit.author"
							:avatar="startCommit.author_avatar"
							:date="startCommit.date"
							@onCommitClick="onCommitClick(startCommit.sha, 'start')"
						/>
						<no-commit-cell
							v-else
							message="The very first commit..."
							@onCommitClick="onCommitClick(null, 'start')"
						/>

						<var-cell>End Commit</var-cell>
						<commit-cell
							:hash="endCommit.sha"
							:message="endCommit.message"
							:hash-short="endCommit.sha_short"
							:user="endCommit.author"
							:avatar="endCommit.author_avatar"
							:date="endCommit.date"
							@onCommitClick="onCommitClick(endCommit.sha, 'end')"
						/>
					</template>
					<var-button type="primary" @click="deploy" :loading="loadingCreate">Deploy</var-button>
				</var-form>
			</template>
		</var-card>
	</var-skeleton>

	<select-commit-modal
		ref="selectCommitModal"
		:server-id="this.formData.server_id"
		:branch="this.formData.branch"
		@onConfirm="onConfirm"
	/>
</template>

<script>
import {reactive, ref} from "vue";
import request from "../request";
import CommitCell from "../components/CommitCell";
import SelectCommitModal from "../components/SelectCommitModal";
import NoCommitCell from "../components/NoCommitCell.vue";
import Breadcrumbs from "../components/Breadcrumbs.vue";

export default {
	name: "CreateDeployment",
	components: {Breadcrumbs, NoCommitCell, SelectCommitModal, CommitCell},
	created() {
		this.getServers()
		this.getBranches()
	},
	setup() {
		const formData = reactive({
			server_id: null,
			branch: null,
			start_commit: null,
			end_commit: null
		})

		const form = ref(null)

		return {
			form,
			formData
		}
	},
	data() {
		return {
			loading: true,
			loadingManifest: false,
			breadcrumbItems: [
				{
					title: 'Projects',
					to: 'Projects',
					params: {}
				}
			],
			loadingCreate: false,
			servers: null,
			branches: null,
			startCommit: null,
			endCommit: null
		}
	},
	methods: {
		getServers() {
			this.loading = true;
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.id + '/project_servers_list')
				.then(data => {
					this.loading = false
					this.servers = data.data
					const project = data.meta.project

					this.breadcrumbItems.push({
						title: project,
						to: 'ProjectDetails',
						params: {
							id: this.$route.params.id
						}
					})
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		getBranches() {
			this.loading = true;
			request.get(process.env.VUE_APP_BASE_URL + '/projects/' + this.$route.params.id + '/branches')
				.then(data => {
					this.loading = false
					this.branches = data
				})
				.catch(error => {
					this.loading = false
					console.log(error)
				})
		},
		getManifest() {
			this.loadingManifest = true;
			request.get(process.env.VUE_APP_BASE_URL + '/servers/' + this.formData.server_id + '/manifest?branch=' + this.formData.branch)
				.then(data => {
					this.loadingManifest = false
					this.startCommit = data.start_commit
					this.endCommit = data.end_commit
				})
				.catch(error => {
					this.loadingManifest = false
					console.log(error)
				})
		},
		selectDefaultBranch() {
			this.formData.branch = this.servers.filter(server => server.id === this.formData.server_id)[0].default_branch

			this.getManifest()
		},
		onCommitClick(sha, type) {
			if (!sha) {
				this.$refs.selectCommitModal.fullDeploy = true
			}
			this.$refs.selectCommitModal.show = true
			this.$refs.selectCommitModal.selectedCommit = sha
			this.$refs.selectCommitModal.type = type
		},
		onConfirm(commit, type, fullDeploy) {
			if (type === 'start') {
				if (fullDeploy) {
					this.startCommit = null
				} else {
					this.startCommit = {...commit}
				}
			} else {
				this.endCommit = {...commit}
			}
		},
		deploy() {
			this.loadingCreate = true
			this.formData.start_commit = this.startCommit?.sha
			this.formData.end_commit = this.endCommit.sha
			request.post(process.env.VUE_APP_BASE_URL + '/servers/' + this.formData.server_id + '/deployments', this.formData)
				.then(data => {
					this.loadingCreate = false
					this.$router.push({
						name: 'DeploymentReport',
						params: {
							project_id: this.$route.params.id,
							deployment_id: data.id
						}
					})
				})
				.catch(error => {
					this.loadingCreate = false
					console.log(error)
				})
		}
	}
}
</script>

<style scoped>

</style>